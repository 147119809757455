export enum EventItemTypes {
    Team = 'Team',
    Pickup = 'Pickup',
  }

export const DefaultEventIcon = 'sports'; // Replace with your actual default icon

export const EventTypeIcons = {
  'Football': 'sports_football',
  'Soccer': 'sports_soccer',
  'Volleyball': 'sports_volleyball',
  'Basketball': 'sports_basketball',
  'Baseball': 'sports_baseball',
  'Lacrosse': DefaultEventIcon,
  'Softball': 'sports_baseball',
  'Pickleball': DefaultEventIcon,
  'Tennis': 'sports_tennis',
  'Badminton': 'sports_tennis',
  'Golf': 'sports_golf',
  /** Leisure */
  'Hiking': 'hiking',
  'Camping': 'nature',
  'Cooking': 'local_fire_department',
  'Reading': 'auto_stories',
  'Coding': 'code',
  'Watch Party': 'movie',
  'Board games': 'casino',
  'Card games': 'diamond',
  'Table top': 'table_restaurant',
  'Trivia': 'question_mark',
  /** Exercise */
  'Running': 'directions_run',
  'Weightlifting': 'fitness_center',
  'Cycling': 'directions_bike',
  'Backpacking': 'backpack',
  /** Extreme */
  'Skateboard': 'skateboarding',
  'eSports': 'sports_esports',
  'Other': DefaultEventIcon,
  // Dance
  'Ballroom Dance': 'music_note',
  'Swing dance': 'music_note',
  'Latin dance': 'music_note',
};
